<template>
  <div>
    <p style="font-size:16px;">正在跳转...</p>
  </div>
</template>

<script>

import {getPayMachNo} from "../api/api";
export default {
  components: {
  },
  mounted() {
    const allQuery = Object.assign({}, this.searchToObject(), this.$route.query)
    console.log('allQuery', allQuery);
    getPayMachNo(allQuery).then(res => {
      //设置channelUserId
      const env = this.detectClient();
      if (env === 'wechat') {
        this.$router.push({name: 'WechatPay', params: res})
      }
      if (env === 'aliPay') {
        this.$router.push({name: 'AliPay', params: res})
      }
      //this.$router.push({name: wayCodeUtils.getPayWay().routeName})
    }).catch(res => {
      console.log('dkdkdkdkddd', res);
    });

  },
  methods: {
    detectClient() {
      const userAgent = window.navigator.userAgent.toLowerCase();

      // 判断是否为微信
      const isWechat = /micromessenger/.test(userAgent);

      // 判断是否为支付宝
      const isAlipay = /alipayclient/.test(userAgent);

      if (isWechat) {
        return 'wechat';
      } else if (isAlipay) {
        return 'aliPay';
      } else {
        return '';
      }
    },
    searchToObject: function() {

      if(!window.location.search){
        return {};
      }

      var pairs = window.location.search.substring(1).split("&"),
          result = {},
          pair,
          i;
      for ( i in pairs ) {
        if ( pairs[i] === "" ) continue;
        pair = pairs[i].split("=");
        result[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
      }
      return result;
    }

  }
}
</script>
